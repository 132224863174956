<!-- eslint-disable -->
<template>
    <div class="ma-0 pa-5"
    >
        <div class="modeldetails" style="border-radius: unset !important;">
          <div v-if="loading">
            <Loader style="height:70vh;" />
          </div>
          <div v-else>
            <div class="px-0 py-5 ml-6 titlebar pb-0">
              <div
                    style="font-size: 18px;"
                    class="a-link cursor-pointer ml-3"
                    @click="$router.go(-1);">
                    <v-icon
                      style="margin-bottom: 2px;"
                      color="#8066ff">mdi-chevron-left</v-icon>Back
                    </div>
                <div
                  class="order-details-container mt-8 pb-0 pt-0">
                <div class="order-details">
                    <div>
                      <div>
                        <span
                          class="ml-1"
                          style="
                            color: #929292;
                            font-size: 14px;
                            font-weight: 500;
                          ">
                          {{orderNumber}}
                        </span>
                        <PageTitle />
                      </div>
                    </div>
                    <div>
                    </div>
                </div>
                </div>
            </div>
            <v-card-text>
                <v-row class="pa-5 pr-0 pl-0 cards-div"
                >
                <v-col sm="12" xs="12" md="9" lg="9" xl="9"
                  class="pl-8 pr-10 cards-options"
                >
                <div class="card-content-body">
                  <div>
                      <div class="heading pb-4">Sweeps (Footage Capture)</div>
                    </div>
                    <div
                    v-for="mediaType in getSweepMediaTypes(
                      rankMediaTypes(Object.keys(groupedAvailableDeliverables))
                    )"
                    :key="mediaType"
                    >
                        <div class="media-type-container">
                        <div class="media-type">{{ mediaType }}</div>
                        <div class="long-line"></div>
                        </div>
                        <div
                          v-for="(sweep, index2) in sweepDeliverables"
                          :key="sweep">
                        <v-expansion-panels
                          multiple flat
                          v-model="expansionPanels[mediaType]"
                        >
                          <OrderDeliverable
                            ref="sweepForm"
                            v-for="(subDeliverable, index)
                              in Object.keys(groupedAvailableDeliverables[mediaType])"
                            :sweepDeliverable="sweep"
                            :title="'Sweep Details'"
                            :state="'Sweeps'"
                            :sweepIndex="index"
                            :deliverableData="
                              groupedAvailableDeliverables[mediaType][subDeliverable]"
                            :key="mediaType+'-'+index"
                            @addSubDeliverable="
                              () => addSubDeliverable({
                                mediaType, subDeliverable
                            }, index2)"
                            @updateCredits="updateCredits"
                          />
                        </v-expansion-panels>
                        <!-- {{ sweepErrors }}
                          :title="getState"
                            :state="getState"
                            :sweepIndex="index"
                        -->
                        <!-- <div
                          v-if="sweepDeliverables.length === 0 || sweepDeliverables.length - 1 === index2"
                          @click="addSweep(index2)">
                          + Add Sweep</div> -->
                        </div>
                    </div>
                    <div>
                      <div class="heading pb-4">Digital Assets</div>
                    </div>
                    <div
                    v-for="mediaType in getAssetMediaTypes(
                      rankMediaTypes(Object.keys(groupedAvailableDeliverables))
                    )"
                    :key="mediaType"
                    >
                        <div class="media-type-container">
                        <div class="media-type">{{ mediaType }}</div>
                        <div class="long-line"></div>
                        </div>
                        <v-expansion-panels
                          multiple flat
                          v-model="expansionPanels[mediaType]"
                        >
                          <OrderDeliverable
                            v-for="(subDeliverable, index)
                            in Object.keys(groupedAvailableDeliverables[mediaType])"
                            :title="subDeliverable"
                            :deliverableData="
                              groupedAvailableDeliverables[mediaType][subDeliverable]"
                            :key="mediaType+'-'+index"
                            @addSubDeliverable="
                              () => addSubDeliverable({
                                mediaType, subDeliverable,
                            })"
                            @updateCredits="updateCredits"
                          />
                        </v-expansion-panels>
                    </div>
                </div>
                </v-col>
                <v-col
                  xs="12"
                  sm="12"
                  md="3"
                  lg="3"
                  xl="3"
                  class="credit-box">
                <div
                  class="credit-bank-wrapper">
                    <div class="credit-bank">
                    <div class="heading">Order Summary</div>
                    <div class="cb-line-item">
                        <div class="cb-line-item-title">Credit Bank</div>
                        <div class="cb-line-item-value">{{creditsAvailable}}</div>
                    </div>
                    <div class="cb-line-item">
                        <div class="cb-line-item-title">Used</div>
                        <div
                          :key="creditsUsed"
                          class="cb-line-item-value">{{creditsUsed}}</div>
                    </div>
                    <v-divider></v-divider>
                    <div class="cb-line-item mt-2" v-if="creditsLeft>=0">
                        <div class="cb-line-item-title">Remaining</div>
                        <div
                          class="cb-line-item-value"
                          :key="creditsLeft">{{creditsLeft}}</div>
                    </div>
                    <div class="cb-line-item mt-2 some-red-text" v-else>
                        <div :key="creditsLeft" class="cb-line-item-title">Extra</div>
                        <div
                          class="cb-line-item-value"
                          :key="creditsLeft">({{Math.abs(creditsLeft)}})</div>
                    </div>
                    <div v-if="creditsLeft<0">
                    <div class="credits-warning mt-2 some-red-text">
                      <div class="credits-warning-inner d-flex">
                        <div class="icon">
                          <v-icon color="#FF371B">mdi-information-outline</v-icon>
                        </div>
                        <div class="text ml-2 mt-1">Customer does not have enough credits</div>
                      </div>
                    </div>
                    <div class="order-action-btn mt-2" v-if="canDraft">
                        <v-btn
                          class="ocb draft"
                          depressed
                          @click="purchaseCredits()"
                        >
                        Purchase ({{Math.abs(creditsLeft)}}) Credits
                        </v-btn>
                    </div>
                    </div>
                    </div>
                    <div class="order-actions">
                    <div class="order-action-btn mt-2" v-if="canDraft">
                        <v-btn
                          class="ocb draft"
                          depressed
                          @click="() => saveOrder('DRAFT')"
                          :loading="draftingOrder"
                        >
                          Save Draft
                        </v-btn>
                    </div>
                    <div class="order-action-btn mt-2">
                        <v-btn
                          class="ocb submit btn-purple"
                          depressed
                          :disabled="creditsLeft<0"
                          @click="() => saveOrder('PENDING_APPROVAL')"
                          :loading="savingOrder"
                        >
                          {{callToActionText}}
                        </v-btn>
                    </div>
                    <v-checkbox
                      v-model="selected"
                      v-if="canDraft && !client"
                      class="mt-3 checkbox"
                    >
                    <template v-slot:label>
                    <span class="checkbox-text">Skip customer approval</span>
                    </template>
                    </v-checkbox>
                    </div>
                </div>
                </v-col>
              </v-row>
            </v-card-text>
          </div>
        </div>
        <v-container
      fluid
      v-if="openPurchaseCredits"
    >
      <Modal
        persistent
        content-class="new-user-form"
        :modal="openPurchaseCredits"
        width="1000px"
        scrollable
      >
        <PurchaseCredits
          :buyCredits="buyCredits"
          :cardDetails="cardDetails"
          :userDetails="userDetails"
          @closemodal="closePurchaseModal"
        />
      </Modal>
    </v-container>
    </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import EventBus from '@/helpers/event-bus';
import Loader from '@/components/common/Loader';
import PageTitle from '@/components/common/PageTitle';
import Modal from '@/components/common/Modal';
import PurchaseCredits from '@/components/dashboard/screens/client/PurchaseCredits';
import OrderDeliverable from './OrderDeliverable';
// import OrderSubDeliverable from './OrderSubDeliverable';

export default {
  name: 'OrderForm',
  components: {
    OrderDeliverable,
    // SweepDeliverable,
    Loader,
    PageTitle,
    Modal,
    PurchaseCredits,
    // OrderSubDeliverable,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return ({
      savedSweeps: '',
      availableSweep: false,
      sweepDeliverables: [],
      groupedAvailableDeliverables: {},
      enableAddOrderDescription: false,
      orderInfo: null,
      creditsUsed: 0,
      loading: true,
      expansionPanels: {},
      cardDetails: [],
      openPurchaseCredits: false,
      savingOrder: false,
      draftingOrder: false,
      sweepErrors: '',
      buyCredits: 0,
      lastUpdated: Date.now(),
      selected: false,
    });
  },
  computed: {
    ...mapGetters('business', ['business']),
    ...mapGetters('user', ['userDetails']),
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    client() {
      return ['client', 'agency_owner', 'agency_member', 'member'].includes(this.role);
    },
    orderNumber() {
      return `Order #${this.orderId.toString().trim()}`;
    },
    creditsLeft() {
      // if the order is already placed,
      // the creditsLeft should not include previously submitted credits
      if (this.orderInfo && ['CREATED', 'DRAFT'].includes(this.orderInfo.status)) {
        return parseInt(this.business.credits, 10) - parseInt(this.creditsUsed, 10);
      }
      // eslint-disable-next-line
      return parseInt(this.business.credits, 10) - (parseInt(this.creditsUsed, 10) - parseInt(this.orderInfo.credits, 10));
    },
    callToActionText() {
      // eslint-disable-next-line
      if (this.orderInfo && ['CREATED', 'DRAFT', 'PENDING_APPROVAL', 'REQUESTED_CHANGES'].includes(this.orderInfo.status)) {
        return 'Submit Order';
      }
      return 'Update Order';
    },
    creditsAvailable() {
      if (this.orderInfo && ['CREATED', 'DRAFT'].includes(this.orderInfo.status)) {
        return parseInt(this.business.credits, 10);
      }
      return parseInt(this.business.credits, 10) + parseInt(this.orderInfo.credits, 10);
    },
    previouslyDebitedCredits() {
      // not in created or draft means it's already placed and debited
      if (this.orderInfo && !['CREATED', 'DRAFT'].includes(this.orderInfo.status)) {
        return this.orderInfo.credits;
      }
      return 0;
    },
    canDraft() {
      return ['CREATED', 'DRAFT'].includes(this.orderInfo.status);
    },
  },
  methods: {
    ...mapActions('user', ['paymentMethods']),
    ...mapActions('business', ['getBusiness', 'getDeliverables', 'getCreditsHistory']),
    ...mapActions('orders', ['getOrderInfo', 'updateOrder']),
    ...mapActions('contentKit', ['getDimensions']),
    purchaseCredits() {
      this.buyCredits = Math.abs(this.creditsLeft);
      this.openPurchaseCredits = true;
    },
    async closePurchaseModal() {
      this.openPurchaseCredits = false;
      this.$router.replace({
        query: {},
      });
      await this.getBusiness(this.userDetails.currentBusinessId);
      this.lastUpdated = Date.now();
    },
    rankMediaTypes(mediaTypes) {
      const ranks = {
        photo: 1,
        video: 2,
        audio: 3,
        'asset add-ons': 4,
        sweeps: 5,
        'sweeps add-ons': 5,
      };
      return _.sortBy(mediaTypes, (mt) => (ranks[mt.toLowerCase()] || Number.MAX_VALUE));
    },
    getAssetMediaTypes(mediaTypes) {
      return _.filter(mediaTypes, (mt) => !/(sweeps|sweep add-ons)/i.test(mt));
    },
    getSweepMediaTypes(mediaTypes) {
      return _.filter(mediaTypes, (mt) => /(sweeps|sweep add-ons)/i.test(mt));
    },
    updateCredits() {
      let creditsUsed = 0;
      const creativeTypes = Object.keys(this.groupedAvailableDeliverables);
      creativeTypes.forEach((mediaType) => {
        const subDeliverables = Object.keys(this.groupedAvailableDeliverables[mediaType]);
        subDeliverables.forEach((subDeliverable) => {
          const container = this.groupedAvailableDeliverables[mediaType][subDeliverable];
          container.deliverables.forEach((deliverable) => {
            creditsUsed += Math.round(deliverable.creditCost * deliverable.quantity);
          });
        });
      });
      this.creditsUsed = creditsUsed;
      this.validateAndGetDeliverables();
    },
    formatDeliverables(deliverables) {
      const deliverablesGroupedByCreativeType = deliverables
        .filter((deliverable) => deliverable.status)
        .reduce((result, item) => {
          if (!result[item.creativeType.name]) {
            // eslint-disable-next-line no-param-reassign
            result[item.creativeType.name] = [];
          }
          // eslint-disable-next-line no-param-reassign
          result[item.creativeType.name].push(item);
          return result;
        }, {});
      const finalFormattedObject = Object.keys(deliverablesGroupedByCreativeType)
        .reduce((result, mediaType) => {
          const options = deliverablesGroupedByCreativeType[mediaType];
          // now group the options by
          const subGroup = options.reduce((subResult, item) => {
            if (!subResult[item.category.name]) {
              // eslint-disable-next-line no-param-reassign
              subResult[item.category.name] = {
                meta: {
                  category: item.category,
                  creativeType: item.creativeType,
                },
                options: [],
                deliverables: [],
              };
            }
            subResult[item.category.name].options.push(item);
            return subResult;
          }, {});
          // eslint-disable-next-line no-param-reassign
          result[mediaType] = subGroup;
          return result;
        }, {});
      return finalFormattedObject;
    },
    // add mulit sweeps in order form
    // addSweep(index) {
    //   console.log('index', index + 1);
    //   this.sweepDeliverables.push({
    //     subId: index + 1,
    //     title: '',
    //     date: moment().format('YYYY-MM-DD'),
    //     fromTime: '08:00',
    //     toTime: '10:00',
    //     timezone: 1,
    //     locations: [{
    //       key: `location-${Date.now()}`,
    //       settings: '1',
    //       location: '',
    //       zipcode: '',
    //     }],
    //     description: '',
    //   });
    // },
    addSubDeliverable(info, index) {
      const { mediaType, subDeliverable } = info;
      const container = this.groupedAvailableDeliverables[mediaType][subDeliverable];
      container.deliverables.push({
        deliverableId: 0,
        creativeType: container.meta.creativeType,
        category: container.meta.category,
        creditCost: 0,
        quantity: mediaType === 'Sweeps' ? 2 : 1,
        dimensions: [],
        subId: index,
        sweeperrors: {
          titleerror: '',
          locationerror: '',
          timeError: '',
          descriError: '',
        },
        locations: 1,
        errors: {
          deliverableType: null,
        },
      });
      // if (info.mediaType === 'Sweeps') {
      //   this.updateCredits();
      // }
    },
    updateSweepDetails() {
      const creatives = [];
      Object.keys(this.groupedAvailableDeliverables).forEach((mediaType) => {
        Object.keys(this.groupedAvailableDeliverables[mediaType])
          .forEach((deliverableType) => {
            // eslint-disable-next-line
            const container = this.groupedAvailableDeliverables[mediaType][deliverableType];
            // eslint-disable-next-line
            container.deliverables = container.deliverables.filter((deliverable) => deliverable.deliverableId);
            container.deliverables.forEach((data) => {
              // eslint-disable-next-line
              this.sweepDeliverables.map((sweep) => {
                if (sweep.subId === data.subId || data.orderId) {
                  // eslint-disable-next-line
                  // data.sweeps = null;
                  // eslint-disable-next-line
                  sweep.deliverables = container.deliverables.length > 0
                    ? container.deliverables : [];
                  // eslint-disable-next-line
                  // data.sweep.businessId = this.userDetails.currentBusinessId;
                  container.options.forEach((creative) => {
                    if (data.deliverableId === creative.id
                      && creative.creativeType.name === 'Sweeps') {
                      creatives.push(creative.name);
                    }
                  });
                  // if (data.deliverableId === container.options[0].id) {
                  //   photographer.push(container.options[0].name);
                  // }
                  // if (data.deliverableId === container.options[1].id) {
                  //   videographer.push(container.options[1].name);
                  // }
                  // if (data.deliverableId === container.options[2].id) {
                  //   fieldproducer.push(container.options[2].name);
                  // }
                }
              });
            });
          });
      });
      const counts = {};
      creatives.forEach((x) => {
        counts[x] = (counts[x] || 0) + 1;
      });
      const creativesName = [...new Set(creatives)];
      const creativeData = creativesName.map((type) => ({
        creative: type,
        quantity: counts[type],
        payout: '',
      }));
      if (creatives.length !== 0) {
        this.sweepDeliverables.forEach((creative) => {
          // eslint-disable-next-line
          creative.creatives = creativeData;
          // eslint-disable-next-line
          creative.businessId = this.orderInfo.businessId;
          // eslint-disable-next-line
          creative.orderId = this.orderInfo.id;
        });
      }
      // eslint-disable-next-line
      this.availableSweep = creatives.length > 0 ? true : false;
    },
    validateAndGetDeliverables() {
      const errors = [];
      const deliverables = [];
      const creativeTypes = Object.keys(this.groupedAvailableDeliverables);
      creativeTypes.forEach((mediaType) => {
        const subDeliverables = Object.keys(this.groupedAvailableDeliverables[mediaType]);
        subDeliverables.forEach((subDeliverable, subDeliverableIndex) => {
          const container = this.groupedAvailableDeliverables[mediaType][subDeliverable];
          container.deliverables.forEach((deliverable) => {
            deliverables.push(deliverable);
            if (mediaType === 'Sweeps') {
              if (!deliverable.deliverableId) {
                // eslint-disable-next-line no-param-reassign
                deliverable.errors.deliverableType = 'Deliverable type is required';
                errors.push(subDeliverableIndex);
                if (deliverable.sweeperrors.titleerror.length > 0
                || deliverable.sweeperrors.locationerror.length > 0
                || deliverable.sweeperrors.timeError.length > 0
                || deliverable.sweeperrors.descriError.length > 0) {
                  errors.push(deliverable.sweeperrors);
                }
              }
              if (deliverable.sweeperrors.titleerror.length > 0
                || deliverable.sweeperrors.locationerror.length > 0
                || deliverable.sweeperrors.timeError.length > 0
                || deliverable.sweeperrors.descriError.length > 0) {
                // eslint-disable-next-line no-param-reassign
                errors.push(deliverable.sweeperrors);
                // this.sweepErrors = 'fill the sweep details';
              }
            }
            if (!deliverable.deliverableId) {
              // eslint-disable-next-line no-param-reassign
              deliverable.errors.deliverableType = 'Deliverable type is required';
              errors.push(subDeliverableIndex);
            } else {
              // eslint-disable-next-line no-param-reassign
              deliverable.errors.deliverableType = null;
            }
          });
          // if (container.deliverables.length === 0 && mediaType === 'Sweeps') {
          //   this.sweepDeliverables.forEach((sweep) => {
          //     container.deliverables.push(({
          //       deliverableId: 0,
          //       creativeType: container.meta.creativeType,
          //       category: container.meta.category,
          //       creditCost: 0,
          //       quantity: 2,
          //       subId: 0,
          //       sweeps: sweep.sweeps,
          //       dimensions: [],
          //       locations: 1,
          //       errors: {
          //         deliverableType: null,
          //       },
          //       sweeperrors: {
          //         titleerror: '',
          //         locationerror: '',
          //       },
          //     }));
          //   });
          // }
        });
      });
      if (errors.length) return null;
      return deliverables;
    },
    async saveOrder(status) {
      EventBus.$emit('checkSweepDetails');
      // EventBus.$emit('checkForLocation');
      // EventBus.$emit('checkForTitle');
      this.updateSweepDetails();
      // remove deliverables in which deliverable type not selected
      Object.keys(this.groupedAvailableDeliverables).forEach((mediaType) => {
        Object.keys(this.groupedAvailableDeliverables[mediaType])
          .forEach((deliverableType) => {
            // eslint-disable-next-line
            const container = this.groupedAvailableDeliverables[mediaType][deliverableType];
            // eslint-disable-next-line
            container.deliverables = container.deliverables.filter((deliverable) => deliverable.deliverableId);
            // container.deliverables.forEach((data) => {
            //   this.sweepde
            // });
            // container.deliverables.forEach((data) => {
            //   // eslint-disable-next-line
            //   this.sweepDeliverables.map((sweep) => {
            //     if (sweep.subId === data.subId) {
            //       // eslint-disable-next-line
            //       data.sweeps = sweep;
            //     }
            //   });
            // });
          });
      });
      const deliverables = this.validateAndGetDeliverables();
      if (!deliverables) return;
      const order = {};
      order.orderId = this.orderInfo.id;
      order.deliverables = deliverables;
      order.sweepDeliverables = this.availableSweep ? this.sweepDeliverables : [];
      order.description = this.orderInfo.description;
      order.status = status;
      order.skipCustomerApproval = this.selected;
      if (status === 'DRAFT') {
        this.draftingOrder = true;
      } else {
        this.savingOrder = true;
      }
      const response = await this.updateOrder({ order });
      const updateHistory = this.getCreditsHistory({ businessId: this.orderInfo.businessId });
      this.savingOrder = false;
      this.draftingOrder = false;
      if (response && updateHistory) {
        this.$router.push('/orders');
      }
    },
  },
  async mounted() {
    try {
      this.loading = true;
      await this.getDimensions();
      const deliverables = await this.getDeliverables();
      if (this.$route.params.businessId) {
        this.orderId = this.$route.params.orderId;
      }
      const orderInfo = await this.getOrderInfo({
        orderId: this.orderId,
      });
      const result = await this.paymentMethods(orderInfo.businessId);
      this.cardDetails = result.paymentMethods;
      await this.getBusiness(orderInfo.businessId);
      this.orderInfo = orderInfo;
      this.creditsUsed = orderInfo.credits;
      this.enableAddOrderDescription = Boolean(orderInfo.description);
      this.groupedAvailableDeliverables = this.formatDeliverables(deliverables);
      this.sweepDeliverables.push({
        subId: 0,
        title: '',
        date: moment().format('YYYY-MM-DD'),
        fromTime: '08:00',
        toTime: '10:00',
        timezone: 1,
        locations: [{
          key: `location-${Date.now()}`,
          settings: '1',
          location: '',
          zipcode: '',
        }],
        deliverables: [],
        notes: '',
        creatives: [],
        orderId: '',
        businessId: '',
        businessIds: [],
      });
      // todo attach sub deliverable info from order info
      let orderDeliverables = _.get(orderInfo, 'orderDeliverables', []);
      // eslint-disable-next-line
      this.savedSweeps = orderDeliverables.find((data) => data.sweeps !== null);
      if (this.savedSweeps !== undefined) {
        // eslint-disable-next-line
        this.sweepDeliverables.map((data) => {
          // eslint-disable-next-line
          data.title = this.savedSweeps.sweeps.title;
          // eslint-disable-next-line
          data.businessId = this.savedSweeps.sweeps.businessId;
          // eslint-disable-next-line
          const businessIds = this.savedSweeps.sweeps.sweepBusiness.map((business) => {
            // eslint-disable-next-line
            if (business.business.id !== this.userDetails.currentBusinessId) {
              // eslint-disable-next-line
              data.businessIds.push(business.business.id);
            }
          });
          // eslint-disable-next-line
          data.notes = this.savedSweeps.sweeps.notes;
          // eslint-disable-next-line
          data.date = this.savedSweeps.sweeps.date;
          // eslint-disable-next-line
          data.toTime = this.savedSweeps.sweeps.toTime;
          // eslint-disable-next-line
          data.fromTime = this.savedSweeps.sweeps.fromTime;
          // eslint-disable-next-line
          data.locations = this.savedSweeps.sweeps.sweepLocations.map((x) => ({
            ...x,
            key: x.id,
          }));
          // eslint-disable-next-line
          data.timezone = this.savedSweeps.sweeps.timezone;
        });
      }
      orderDeliverables = orderDeliverables.map((orderDeliverable) => {
        const { deliverable } = orderDeliverable;
        // eslint-disable-next-line
        orderDeliverable.category = deliverable.category.name;
        // eslint-disable-next-line
        orderDeliverable.creativeType = deliverable.creativeType.name;
        // eslint-disable-next-line
        orderDeliverable.dimensions = orderDeliverable.dimensions.map((d) => d.dimensionId);
        // eslint-disable-next-line
        orderDeliverable._category = deliverable.category;
        // eslint-disable-next-line
        orderDeliverable._creativeType = deliverable.creativeType;
        // eslint-disable-next-line
        orderDeliverable.errors = {
          deliverableType: null,
        };
        // eslint-disable-next-line
        orderDeliverable.sweeperrors = {
          descriError: '',
          locationerror: '',
          timeError: '',
          titleerror: '',
        };
        // eslint-disable-next-line
        return orderDeliverable;
      });
      orderDeliverables.forEach((orderDeliverable) => {
        const { category, creativeType } = orderDeliverable;
        const creditCost = _.get(orderDeliverable, 'deliverable.creditCost', 0);
        // eslint-disable-next-line
        orderDeliverable.creditCost = creditCost;
        if (!this.groupedAvailableDeliverables[creativeType]) {
          // eslint-disable-next-line no-param-reassign
          this.groupedAvailableDeliverables[creativeType] = {};
        }
        let container = this.groupedAvailableDeliverables[creativeType][category];
        // PATCH: the deliverable might be made inactive but been selected in the order,
        //  below snippets will handle this case
        // and show the deliverable in the order even if its inactive.
        if (!container) {
          this.groupedAvailableDeliverables[creativeType][category] = {
            meta: {
              // eslint-disable-next-line
              category: orderDeliverable._category,
              // eslint-disable-next-line
              creativeType: orderDeliverable._creativeType,
            },
            options: [],
            deliverables: [],
          };
          container = this.groupedAvailableDeliverables[creativeType][category];
        }
        // push option to container if not exist
        const option = {
          ...orderDeliverable.deliverable,
          // eslint-disable-next-line
          category: orderDeliverable._category,
          // eslint-disable-next-line
          creativeType: orderDeliverable._creativeType,
        };
        if (!container.options.find((o) => o.id === option.id)) {
          container.options.push(option);
        }
        container.deliverables.push(orderDeliverable);
      });
      // set expansion panels
      Object.keys(this.groupedAvailableDeliverables).forEach((mediaType) => {
        Object.keys(this.groupedAvailableDeliverables[mediaType])
          .forEach((deliverableType, index) => {
            const container = this.groupedAvailableDeliverables[mediaType][deliverableType];
            // if (mediaType === 'Sweeps') {
            //   this.expansionPanels[mediaType] = [0];
            // }
            if (container.deliverables.length) {
              this.expansionPanels[mediaType] = [
                ...new Set([...(this.expansionPanels[mediaType] || []), index]),
              ];
            }
          });
      });
      this.loading = false;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  },
};
</script>
<style lang="scss" scoped>
  .credit-bank-wrapper{
    margin-top: 20px;
    position: sticky !important;
    top: 80px;
  }
  @media screen and (max-width: 1000px){
    .credit-bank-wrapper{
      width: 100% !important;
      position: relative !important;
      top: 0px;
      margin-top: 0px;
    }
  }
  @media (max-width: 600px) {
    .titlebar {
      margin-left: 0px !important;
    }
    .order-details-container {
      padding-left: 0px !important;
    }
    .a-link {
      margin-left: 0px !important;
    }
    .cards-div {
      display: flow !important;
    }
    .cards-options {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
  .title-header {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 13px;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #929292;
  }
  .heading {
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    color: #929292;
  }
 .order-details-container {
    padding: 10px;
    .order-details-ad-button {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $secondary1;
      cursor: pointer;
    }
    .order-details-description {
      width: 100%;
      height: 100px;
      border: 1px solid #e5e8ed;
      border-radius: 4px;
      padding: 10px;
      resize: none;
      &:focus {
        outline: none;
      }
    }
    .order_number  {
      margin: 0rem 0rem 1rem 0rem;
      div {
        font-size: 16px;
        padding: 0.5rem 2rem;
        width: fit-content;
        color: #505050;
        background: #f3f3f3;
        border: 1px solid #babfc7;
        min-width: 200px;
        letter-spacing: none !important;
      }
    }
  }
  .media-type-container {
    display: flex;
    align-items: center;
    .media-type {
      font-weight: 600;
      color: #bdbdbd;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      position: relative !important;
      width: fit-content;
      text-transform: uppercase;
    }
    .long-line {
      flex: 1;
      margin-left: 1rem;
      height: 2px;
      background: #e0e0e0;
    }
  }
    .cb-line-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.2rem 0rem;
    color: #8C8C8C;
  }
.credit-bank {
    border: 1px solid #e0e0e0;
    border-radius: 0.3rem;
    padding: 1rem;
    .heading {
      color: #383838;
    }
  }
  .order-action-btn {
    .ocb {
      width: 100%;
      border-radius: 1.5rem;
      height: 44px;
      .v-btn__content {
        font-size: 16px;
        line-height: 28px;
      }
    }
    .ocb.draft {
      border: 2px solid $secondary2;
      .v-btn__content {
        font-weight: 500;
        color: #262626;
      }
    }
    .ocb.submit {
      color: white;
      background: $secondary1 !important;
      .v-btn__content {
        font-weight: 600;
      }
    }
  }
  .loader{
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  img {
    width: 100px;
    height: 100px;
  }
}
.some-red-text {
  color: #FF371B;
}
.credits-warning {
  color: #FF371B;
  font-size: 13px;
  font-weight: 400;
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
  border: 1px solid #FF371B;
  // max-width: fit-content;
}
::v-deep .v-dialog{
  overflow: auto !important;
  overflow-x:hidden !important;
  position:relative;
}
// .titlebar{
//   position: sticky !important;
//   position:-webkit-sticky;
//   z-index: 5;
//   top:0;
// }
.card-content-body{
  z-index: 1;
}
.checkbox{
  padding-left:10px !important;
}
.checkbox-text{
  font-size:14px;
  color:#262626;
}
</style>
