<template>
  <v-container fluid pa-0 class="invitation-screen">
    <Loader v-if="loading" />
    <template v-else>
      <template v-if="showForm">
        <SweepForm
          :sweepObj="sweep"
          @cancel="cancelEdit"
          @openCreativeMatchingForm="openCreativeForm"
          @reload-sweeps="fetchSweep"
        />
      </template>
      <template v-else>
        <v-row class="ma-0 mobile-padding">
          <v-col cols="12" xs="12" sm="1" md="2"> </v-col>
          <v-col cols="12" xs="12" sm="10" md="8">
            <v-btn text :to="{ name: 'Dashboard' }" class="pl-0 mb-1 sweeps-back-btn">
              <v-icon> mdi-chevron-left </v-icon> Back to Dashboard
            </v-btn>
            <SweepDetailsCard :sweep="sweep" showTeamVertical class="ml-2" v-if="!loading">
              <template
                v-slot:invitation-actions
                v-if="
                  creative && pendingInvitation && invitationExpiryTime > 0
                "
              >
              <!-- && isSweepNotElapsed -->
                <v-btn
                  class="btn-rounded-pill mr-3 btn-purple btn-primary-small"
                  small
                  v-on:click="
                    availabilityCheck === '2'
                      ? acceptPopup(sweep.id, '1')
                      : updateInvitation('1', false)
                  "
                >
                  Accept
                </v-btn>
                <v-btn
                small
                outlined
                color="info"
                class="btn-rounded-pill btn-primary-small"
                @click="updateInvitation('0', true)">
                  Decline
                </v-btn>
              </template>
              <!-- <template v-if="!creative" v-slot:actions>
                <v-col class="d-flex justify-end pa-0" md="3" v-if="isSweepNotElapsed">
                  <v-btn small icon color="black lighten-2" @click="showForm = true">
                    <img src="@/assets/svg/theme/edit.svg" />
                  </v-btn>
                  <v-btn small icon color="black lighten-2" @click="deleteDialog = true">
                  <img
                      src="@/assets/svg/theme/trash.svg"
                      class="download-icon"
                    />
                  </v-btn>
                </v-col>
              </template> -->
              <template v-if="pendingInvitation && isSweepNotElapsed" v-slot:actions>
                <v-col class="d-flex justify-end align-center pa-0">
                  <div class="sweep-expirty-time py-1 px-2">
                    {{ formattedExpiryTime }}
                  </div>
                </v-col>
              </template>
              <template v-if="creative && creativePayTier" v-slot:tier-details>
                <div class="pt-1">
                  <span class="sub-heading"> Pay Tier: </span>
                  {{ tier }}
                </div>
                <div class="pt-1">
                  <span class="sub-heading"> Estimated Total Rate: </span>
                  ${{ invitation.estTotal }}
                </div>
                <div class="pt-1">
                  <span class="sub-heading"> Content Producer: </span>
                  {{ contentProducer }}
                </div>
              </template>
            </SweepDetailsCard>
          </v-col>
          <v-col cols="12" xs="12" sm="1" md="2"> </v-col>
        </v-row>
      </template>
    </template>
    <template v-if="showInvitationPopup" class="pa-0">
      <Modal :modal="showInvitationPopup" width="600" persistent>
        <InvitationDialog
          :creative="user"
          :invitationStatus="invitationStatus"
          @submit-invitation="submitInvitationForm"
          @close-invitation-dialog="showInvitationPopup = false"
          :askForReason="askReason"
        >
        </InvitationDialog>
      </Modal>
    </template>
    <v-dialog v-model="deleteDialog" max-width="500">
      <v-card class="modal-card pa-4" flat>
        <v-card-title class="black--text">
          Do you really want to delete the sweep?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primaryGray1" class="py-0" @click="deleteDialog = false">
            Cancel
          </v-btn>
          <v-btn
            text
            color="info"
            type="submit"
            class="pa-0 ml-2"
            @click="deleteSweep(selectedSweepId)"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <template v-if="showConfirm">
      <v-dialog v-model="showConfirm" max-width="680"
        ><v-card class="overflow-hidden" flat>
          <div class="d-flex justify-end">
            <v-icon class="pa-4 pb-0 icon-height" @click="showConfirm = false">
              mdi-close
            </v-icon>
          </div>
          <v-card-text class="pb-1 black--text font-family-2 card-text">
            Looks like your status is set to Inactive. Tap on Confirm to set your status to Active
            and accept this invite.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="showConfirm = false" class="py-0" color="primaryGray1">
              Cancel
            </v-btn>
            <v-btn text color="info" @click="updateUserStatusApi()" class="py-0">
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import SweepDetailsCard from '@/components/projects/sweep/SweepDetailsCard';
import SweepForm from '@/components/projects/sweep/SweepForm';
import InvitationDialog from '@/components/projects/sweep/InvitationDialog';
import Modal from '@/components/common/Modal';
import store from '@/store';
import EventBus from '@/helpers/event-bus';
import Loader from '@/components/common/Loader';

export default {
  components: {
    InvitationDialog,
    Modal,
    SweepDetailsCard,
    SweepForm,
    Loader,
  },
  props: {
    inviteExpiry: {
      default: 12,
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),

    role() {
      return _.get(this.userDetails, 'role.name');
    },
    availabilityCheck() {
      return this.userDetails.availability;
    },
    creative() {
      return this.role === 'creative';
    },
    invitation() {
      return _.find(this.sweep.sweepTeam, ['userId', this.userDetails.id]);
    },
    pendingInvitation() {
      if (this.invitation.creatives.id === _.get(this.userDetails, 'id')) {
        if (this.invitation.accepted || this.invitation.declinedAt) {
          return false;
        }
      }
      return true;
    },
    sweepSkillsets() {
      const skills = _.filter(this.skillsets, (skill) => _.map(this.sweepSkills, 'text').includes(skill.name));
      return _.forEach(skills, (skill) => {
        if (skill.name === 'Other') {
          // eslint-disable-next-line no-param-reassign
          skill.name = 'Talent';
        }
      });
    },
    invitationExpiryTime() {
      const expiration = moment(this.invitation.invitationSentAt).add(this.userDetails.inviteExpiry, 'hours');
      return expiration.diff(moment(), 'minutes');
    },
    isSweepNotElapsed() {
      let booleanVal = false;
      if (moment(this.sweep.date).isAfter(moment().format('YYYY-MM-DD'))) {
        booleanVal = true;
      }
      if (moment(this.sweep.date).isBefore(moment().format('YYYY-MM-DD'))) {
        booleanVal = false;
      }
      if (moment(this.sweep.date).isSame(moment().format('YYYY-MM-DD'))) {
        return moment(this.sweep.fromTime, ['HH:mm']).isAfter(moment());
      }
      return booleanVal;
    },
    formattedExpiryTime() {
      let hours = this.invitationExpiryTime;
      hours = hours > 0 ? hours : '';
      // if (hours > 0) {
      //   return `${parseInt(hours / 60, 0)} hr ${parseInt(
      //     hours % 60,
      //     0,
      //   )} m remaining`;
      // }
      return `${parseInt(hours / 60, 0)} hr ${parseInt(hours % 60, 0)} m remaining`;
    },
    contentProducer() {
      const producer = _.get(this.invitation, 'assignedUser');
      const name = `${producer.firstName} ${producer.lastName}`;
      return name;
    },
    creativePayTier() {
      return _.get(this.invitation, 'creativePayRate');
    },
    tier() {
      if (this.creativePayTier) {
        let tier = `${_.capitalize(this.creativePayTier.tier)} at $${this.creativePayTier.rate}`;
        if (this.creativePayTier.skillset.name !== 'Talent') {
          tier += '/hr';
        }
        return tier;
      }
      return '';
    },
  },
  data() {
    return {
      loading: false,
      sweep: {},
      user: {},
      access: true,
      showInvitationPopup: false,
      invitationStatus: '',
      showForm: false,
      creativeMatching: false,
      deleteDialog: false,
      askReason: true,
      showConfirm: false,
    };
  },
  methods: {
    ...mapActions('sweep', ['getSweep', 'updateInvitationById', 'removeSweep']),
    ...mapActions('profile', ['getCreativeOptions']),
    ...mapActions('user', ['creativeStatusUpdate']),
    updateInvitation(status, needReason) {
      this.invitationStatus = status;
      this.askReason = needReason;
      this.showInvitationPopup = true;
    },
    acceptPopup(sweepId, status) {
      this.sweep.id = sweepId;
      this.invitationStatus = status;
      this.showConfirm = true;
    },
    async updateUserStatusApi() {
      const response = await this.updateInvitationById({
        status: this.invitationStatus,
        sweepId: this.sweep.id,
      });
      if (response.success) {
        const user = { ...this.userDetails, availability: '1' };
        store.commit('user/SET_USER', user);
        this.showConfirm = false;
        EventBus.$emit('sweep-invite-counts', this.invitationStatus);
        this.$router.push({ path: '/dashboard' });
      }
    },
    openCreativeForm(skill) {
      this.currentSkillId = _.find(this.sweepSkillsets, ['name', skill]).id;
      this.creativeMatching = true;
      EventBus.$emit('toggle-navigation', true);
    },

    async submitInvitationForm(reasonId) {
      const result = await this.updateInvitationById({
        reasonId,
        status: this.invitationStatus,
        sweepId: this.sweep.id,
      });
      if (result.success) {
        EventBus.$emit('sweep-invite-counts', this.invitationStatus);
        this.$router.push({ path: '/dashboard' });
      }
    },
    cancelEdit() {
      this.showForm = false;
      this.fetchSweep();
    },

    async fetchSweep() {
      this.loading = true;
      this.sweep = await this.getSweep(this.$route.params.sweepId);
      this.loading = false;
      if (!this.sweep) {
        this.$router.push({ path: '/dashboard' });
      }
    },

    async deleteSweep() {
      const result = await this.removeSweep(this.sweep.id);
      if (result.success) {
        this.$router.push({ path: '/dashboard' });
      }
      this.deleteDialog = false;
    },
  },
  mounted() {
    this.user = this.userDetails;
    this.fetchSweep();
    if (!this.creative) {
      this.getCreativeOptions();
    }
  },
};
</script>

<style scoped lang="scss">
.invitation-screen {
  color: black;
  font-family: $fontFamily1;
}
.font-family-2 {
  font-family: $fontFamily1;
}
.heading,
.sub-heading {
  font-weight: bold;
  padding: 5px 0;
}
.heading {
  font-size: 18px;
}
.sub-heading {
  font-size: 16px;
}
@media (max-width: 600px) {
  .mobile-padding {
    padding-top: 20px;
    padding-right: 0px;
  }
}
</style>
