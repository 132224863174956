<template>
  <v-card class="pa-6 pt-4 sweep-details-card">
    <v-row class="ma-0">
      <v-col class="d-flex align-center pa-0" md=9>
        <div
        class="heading font-h4 notranslate">
        {{sweep.title}}</div>
      </v-col>
      <v-col class="d-flex justify-end pa-0" md=3 v-if="canEdit">
       <slot name="actions"></slot>
      </v-col>
    </v-row>
    <div class="mb-2">
      <div> <span class="sub-heading font-h5 mr-1"> Business Name:
        <span class="notranslate"> {{projectName}}</span></span></div>
    </div>
    <div class="py-1 align-center">
      <span style="height: 20px; width: 18px;">
        <v-icon> mdi-calendar-outline </v-icon>
      </span>
      <span class="pl-2 font-body-small"> {{sweepDate}} </span>
    </div>
    <div class="py-1 d-flex align-center" style="margin-left: 3px;">
      <span style="height: 20px; width: 20px;">
        <Clock color="rgba(0, 0, 0, 0.54)"/>
      </span>
      <span class="pl-3 font-body-small" :key="sweep.fromTime">{{sweepTime(sweep.fromTime)}}</span>-
      <span class="font-body-small" :key="sweep.toTime">{{sweepTime(sweep.toTime)}}</span>
    </div>
    <div class="py-1 row">
            <v-col class="pt-0" style="max-width: 18px;margin-left: 3px;">
              <MapMarker color="rgba(0, 0, 0, 0.54)" width="19" height="20" />
            </v-col>
              <v-col class="ml-2 pt-1 font-body-small">
              <div class="font-body" v-if="sweep.sweepLocations.length === 0">
                -
              </div>
              <div v-else-if="sweep.sweepLocations">
                <div v-for="(location, i) in sweep.sweepLocations" :key="i" class="pb-1">
                  <div class="locationCount" v-if="sweep.sweepLocations.length > 1">
                    Location #{{ i + 1 }}
                  </div>
                  <div>
                    {{
                      `${!location.location && !location.zipcode ? '-' : location.location || ''}${
                        location.zipcode ? `, ${location.zipcode}` : ''
                      }`
                    }}
                  </div>
                </div>
              </div>
              </v-col>
    </div>
    <div>
      <slot name="tier-details" class="py-1"></slot>
    </div>
    <div class="mb-3">
      <div class="sub-heading font-body"> Creatives Matched: </div>
      <template v-if="sweepMembers.length">
        <div class="sweep-team">
        <CreativeTeam
          :creativeTeam="sweepMembers"
          :openPopupLeft="false"
          class="pt-2 w-100"
          :vertical="showTeamVertical"
          :openPopupBottom="true"
          :openDetailsPopup="true"
        />
        </div>
      </template>
      <template v-else class="font-body-small">
        - No creatives matched yet
      </template>
    </div>
    <div class="sub-heading font-body details"> Sweep Details: </div>
    <div v-linkified v-html="sweep.notes"> </div>
    <v-card-actions class="pa-0">
      <slot name="invitation-actions"></slot>
    </v-card-actions>
    <div class="mb-3" v-if="!['client', 'member'].includes(role) && sweepBusinesses.length">
      <template v-if="isORGSweep">
        <div class="sub-heading font-body"> Organization: </div>
      </template>
      <template v-else>
        <div class="sub-heading font-body"> Businesses: </div>
      </template>
      <span class="notranslate font-small"> {{sweepBusinesses.toString()}}</span>
    </div>
  </v-card>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import linkify from 'vue-linkify';
import { mapGetters } from 'vuex';
import CreativeTeam from '@/components/dashboard/screens/common/CreativeTeam';
import MapMarker from '@/components/common/svg/MapMarker';
import Clock from '@/components/common/svg/Clock';

export default {
  components: {
    CreativeTeam,
    MapMarker,
    Clock,
  },
  directives: {
    linkified: linkify,
  },
  props: {
    sweep: {
      type: Object,
      required: true,
    },
    showTeamVertical: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goToSweepDetails(val) {
      const sweepId = val;
      return this.$router.push({
        name: 'Business Sweep Details',
        params: {
          sweepId,
        },
      });
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('project', ['currentProject']),
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    canNotUpdate() {
      // eslint-disable-next-line
      return _.includes(['editor', 'client', 'member', 'agency_owner', 'agency_member'], this.role);
    },
    canEdit() {
      // const path = _.includes(['Sweeps', 'Sweep Details', 'Project'], this.$route.name);
      const path = _.includes(['Sweeps', 'Sweep Details'], this.$route.name);
      if (path && !this.canNotUpdate) {
        return true;
      }
      return this.isEditable;
    },
    isEditable() {
      const team = _.get(this.currentProject, 'business.businessTeam', []);
      const assigned = !!team.find((x) => `${x.userId}` === `${this.userDetails.id}`);
      // return (!this.canNotUpdate && assigned && this.currentProject.status !== '2');
      return (!this.canNotUpdate && assigned) || this.role === 'admin' || this.role === 'executive';
    },
    sweepDate() {
      return moment(this.sweep.date).format('MMMM D, YYYY');
    },
    sweepTime() {
      return (time) => moment((time), ['HH:mm']).format('h:mm A');
    },
    projectName() {
      return _.get(this.sweep, 'business.name', '');
    },
    isORGSweep() {
      const role = _.get(this.userDetails, 'role.name');
      return ((this.sweep.sweepBusiness.length) && (['client', 'member'].includes(role)));
    },
    sweepBusinesses() {
      const role = _.get(this.userDetails, 'role.name');
      let data = [];
      const sweepBusiness = _.get(this.sweep, 'sweepBusiness', []);
      if (['client', 'member'].includes(role)) {
        data = sweepBusiness.filter(
          (sweep) => (this.sweep.businessId === sweep.businessId),
        );
      }
      data = sweepBusiness.filter(
        (sweep) => (this.sweep.businessId !== sweep.businessId),
      );
      return _.map(data, 'business.name');
    },
    sweepSkill() {
      return (skill) => {
        switch (skill) {
          case '1':
            return 'Photographer';
          case '2':
            return 'Videographer';
          case '3':
            return 'Talent';
          default:
            return 'Field Producer';
        }
      };
    },
    activeSweepTeam() {
      return _.filter(this.sweep.sweepTeam, (team) => team.accepted && !team.deletedAt);
    },
    sweepMembers() {
      return _.map(_.filter(this.activeSweepTeam), (team) => ({
        profilePic: team.creatives.profilePic,
        smallImage: team.creatives.smallImage,
        thumbnail: team.creatives.thumbnail,
        firstName: team.creatives.firstName,
        lastName: team.creatives.lastName,
        skill: this.sweepSkill(team.skill),
        number: team.creatives.number,
        email: team.creatives.email,
        bio: _.get(team.creatives, 'creativeProfile.bio', ''),
        userPronouns: team.creatives.userPronouns,
        availability: team.creatives.availability,
        role: 'creative',
        userId: team.creatives.id,
        deletedAt: team.creatives.deletedAt,
      }));
    },
  },
};
</script>

<style scoped lang="scss">
.sweep-details-card {
    color: black;
    font-family: $fontFamily1;
  }
  .locationCount {
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #929292;
    }
  .heading, .sub-heading {
    padding: 5px 0;
  }
  // .sweep-team {
  //   height: 60px;
  // }
@media (max-width: 960px) {
  .sweep-details-card {
    margin-left: 0px !important;
  }
}
</style>
